import React from 'react'
import ContentWrapper from '../component/ContentWrapper/ContentWrapper'
import Footer from '../component/landing/Footer'

export default function PrivacyPolicy() {
  return (
    <>

      <ContentWrapper
        type={"PrivacyPolicy"}
        title={["Privacy", "POLICY"]}
        content={"<h1>Just and HTML</h1>"}
      />

      <Footer />

    </>
  )
}
