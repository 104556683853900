import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import BarooomLandin from "./pages/BarooomLandin";
import Blogs from "./pages/Blogs";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermCondion from "./pages/TermCondion";
import BlogDetails from "./pages/BlogDetails";
import ContactUs from "./pages/ContactUs";
import DeleteAcc from "./pages/DeleteAcc";
import './App.css'
function App() {
  console.log("Version 1.0.0");
  
  return (
    <>
      <Routes>
        <Route path="/" element={<BarooomLandin />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:bid" element={<BlogDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermCondion />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/delete-account" element={<DeleteAcc />} />
      </Routes>
    </>
  );
}

export default App;
