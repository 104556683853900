import React, { useState } from "react";
import { toast } from "react-toastify";
import { createEarlyRegi } from "../axios/axios";
import {
  ensureCusEmail,
  ensureEmail,
  ensureNumber,
} from "../utils/inputFormatter";

function EarlyRegiForm() {
  const [data, setdata] = useState({
    fullName: "",
    email: "",
    phoneNo: "",
    role: "",
    businessName: "",
    location: "",
    barNo: "",
    challanges: "",
    aboutUs: "",
    comments: "",
  });

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setdata({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    if (validateFormData(data)) {
      createEarlyRegi({ data })
        .then((res) => {
          toast.success(res.data.message);
          setdata({
            fullName: "",
            email: "",
            phoneNo: "",
            role: "",
            businessName: "",
            location: "",
            barNo: "",
            challanges: "",
            aboutUs: "",
            comments: "",
          });
        })
        .catch((err) => toast.error(err?.message));
    }
  };

  const validateFormData = (data) => {
    let isValid = true;
    if (!data.fullName) {
      toast.error("Full Name is required");
      return false;
    }

    if (!data.email) {
      toast.error("Email is required");
      return false;
    }
    // else if (ensureCusEmail({ value: data.email })) {
    //   toast.error("Email is invalid");
    //   return false;
    // }

    if (!data.phoneNo) {
      toast.error("Phone Number is required");
      return false;
    } else if (!/^\d+$/.test(data.phoneNo)) {
      toast.error("Phone Number is invalid");
      return false;
    } else if (data.phoneNo.length < 10) {
      toast.error("Phone Number must be at least 10 digits long");
      return false;
    }

    if (!data.role) {
      toast.error("Role is required");
      return false;
    }
    if (data.role && data.role == 2) {
      if (!data.businessName) {
        toast.error("Business Name is required");
        return false;
      }

      if (!data.barNo) {
        toast.error("Bar Number is required");
        return false;
      }
      if (!data.challanges) {
        toast.error("Challenges are required");
        return false;
      }
    }

    // if (!data.location) {
    //   toast.error("Location is required");
    //   return false;
    // }

    // if (!data.aboutUs) {
    //   toast.error("How you heard about us is required");
    //   return false;
    // }

    return isValid;
  };
  return (
    <div class="sec7bg">
      <div class="sec7main">
        <div class="sec7mid">
          <div class="sec7white">
            <div class="sec7content">
              <div class="sec7top">
                <h1>Sign Up Now for Early Access!</h1>
                <h5>
                  Be the first to experience future of establishments staffing &
                  bartending gigs.
                </h5>
              </div>
              <div class="labels">
                <div class="inputbox">
                  <label for="emailname">
                    Full Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    id="fullName"
                    value={data.fullName}
                    onChange={(e) => handleChange(e)}
                    name="fullName"
                    required
                  />
                </div>
                <div class="inputbox">
                  <label for="emailname">
                    Email Address<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    id="email"
                    value={data.email}
                    onChange={(e) => {
                      const value = ensureEmail({
                        value: e.target.value,
                      });
                      setdata({
                        ...data,
                        email: value,
                      });
                    }}
                    name="email"
                    required
                  />{" "}
                </div>
                <div class="inputbox">
                  <label for="emailname">
                    Phone Number<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    id="phoneNo"
                    value={data.phoneNo}
                    onChange={(e) => {
                      const value = ensureNumber({
                        value: e.target.value,
                        maxLength: 10,
                      });
                      setdata({
                        ...data,
                        phoneNo: value,
                      });
                    }}
                    min={10}
                    name="phoneNo"
                    required
                  />{" "}
                </div>
              </div>
              <div class="radio">
                <h1>Are You a Establishments Owner or Bartender?</h1>
                <div class="checkbox">
                  <input
                    type="radio"
                    name="role"
                    value="2"
                    checked={data.role === "2"}
                    onChange={(e) => handleChange(e)}
                    className="radio1"
                  />{" "}
                  <label for="checkbox">Establishments Owner</label>
                  <input
                    type="radio"
                    name="role"
                    value="1"
                    checked={data.role === "1"}
                    onChange={(e) => handleChange(e)}
                    className="radio1"
                  />{" "}
                  <label for="checkbox">Bartender</label>
                </div>
              </div>
              <div class="labels">
                <div class="inputbox">
                  {data?.role == 2 && (
                    <div className="inputbox">
                      <label htmlFor="emailname">
                        Business Name <span className="text-error">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        id="businessName"
                        value={data.businessName}
                        onChange={(e) => handleChange(e)}
                        name="businessName"
                        required
                      />
                    </div>
                  )}
                </div>
                <div class="inputbox">
                  <label for="emailname">Location</label>
                  <input
                    type="text"
                    placeholder=""
                    id="location"
                    value={data.location}
                    onChange={(e) => handleChange(e)}
                    name="location"
                    required
                  />{" "}
                </div>
                {data?.role == 2 && (
                  <div className="inputbox">
                    <label htmlFor="emailname">
                      Number of Bartenders Needed{" "}
                      <span className="text-error">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      id="barNo"
                      value={data.barNo}
                      onChange={(e) => {
                        let value = ensureNumber({
                          value: e.target.value,
                          maxLength: 4,
                        });
                        setdata({ ...data, barNo: value });
                      }}
                      name="barNo"
                      required
                    />
                  </div>
                )}
                {data?.role == 2 && (
                  <div className="inputbox">
                    <label htmlFor="emailname">
                      What are your biggest staffing challenges?
                      <span className="text-error">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      id="challanges"
                      value={data.challanges}
                      onChange={(e) => handleChange(e)}
                      name="challanges"
                      required
                    />
                  </div>
                )}
              </div>
              <div class="radio">
                <h1>How Did You Hear About Us?</h1>
                <div class="checkbox">
                  <input
                    type="radio"
                    name="aboutUs"
                    value="Social Media"
                    checked={data.aboutUs === "Social Media"}
                    onChange={handleChange}
                    className="radio1"
                  />{" "}
                  <label for="checkbox">Social Media</label>
                  <input
                    type="radio"
                    name="aboutUs"
                    value="Friend/Colleag"
                    checked={data.aboutUs === "Friend/Colleag"}
                    onChange={handleChange}
                    className="radio1"
                  />{" "}
                  <label for="checkbox">Friend/Colleague</label>
                  <input
                    type="radio"
                    className="radio1"
                    name="aboutUs"
                    value="Online Search"
                    checked={data.aboutUs === "Online Search"}
                    onChange={handleChange}
                  />{" "}
                  <label for="checkbox">Online Search</label>
                  <input
                    type="radio"
                    className="radio1"
                    name="aboutUs"
                    value="Other"
                    checked={data.aboutUs === "Other"}
                    onChange={handleChange}
                  />{" "}
                  <label for="checkbox">Other</label>
                </div>
              </div>
              <div class="labels">
                <div class="inputbox">
                  <label for="emailname">Other Comments</label>
                  <input
                    type="text"
                    placeholder=""
                    id="comments"
                    value={data.comments}
                    onChange={(e) => handleChange(e)}
                    name="comments"
                    required
                  />{" "}
                </div>
              </div>
              <div class="sec7btn">
                <button
                  onClick={() => handleSubmit()}
                  style={{ cursor: "pointer" }}>
                  Get Started <img src="/images/arrow.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarlyRegiForm;
