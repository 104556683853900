import React, { useEffect, useState } from 'react'
import { FetchBlogsList } from '../axios/axios'
import './Blogs.css'
import Footer from '../component/landing/Footer'
import { useSelector } from 'react-redux'
import style from "../component/ContentWrapper/ContentWrapper.module.css";
import { Link, useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

const Blogs = () => {
  const user = useSelector((state) => state?.user?.user);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [data, setdata] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  })

  const getBlogs = () => {
    FetchBlogsList({ page: 1 }).then((result) => {
      let res = result.data;
      let x = { ...data };
      x = {
        ...x,
        next: res.next,
        prev: res.prev,
        history: [...res.history],
      };
      setdata(x);
      setLoading(false)
    }).catch((err) => {
      console.log(err);
      setLoading(false)
    })
  }

  useEffect(() => {
    getBlogs()
  }, [])

  return (
    <>
      <div
        style={{
          background: `url(/AboutBarrom/backgroundImg.png)`,
          backgroundSize: "cover",
        }}>
        {/* <header className={style.header}>
          {user ? (
            <></>
          ) : (
            <>
              <Link to={"/login"} className={style.changeColor}>
                Login
              </Link>
              <Link to={"/signup"} className={style.changeColor}>
                Signup
              </Link>
            </>
          )}
        </header> */}
        <Link to={"/"}>
          <img
            loading="lazy"
            className={style.barrom}
            src={"/LandingPageImages/Barrom.svg"}
            alt=""
          />
        </Link>
        <div className={style.lowerPart}>
          <div className={style.backgroundImg}>
            <h3>
              Barooom <span>Blogs</span>
            </h3>
          </div>
        </div>
      </div>
      <div className="blogs-container">
        <div className="blogs-header">
          <p style={{ fontFamily: 'poppins', fontSize: '18px' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque cumque soluta repellendus, dolores quae earum facilis saepe minima expedita animi et culpa. Nihil temporibus reiciendis a labore odit. A illo ut eos.
          </p>
        </div>
        {!loading ?
          <div className="blogs-list">
            {data.history.length > 0 &&
              data.history.map((elem) => (
                <div key={elem._id} className="blog-item" onClick={() => navigate(`/blogs/${elem._id}`)}>
                  <img
                    loading="lazy"
                    className="blog-img"
                    src={`https://d28qcxi1juf339.cloudfront.net/${elem?.blogImg}`}
                    alt={elem.title}
                  />
                  <p className="blog-title">{elem.title}</p>
                </div>
              ))
            }
          </div>
          :
          <div className='loading-container'>
            <CircularProgress />
            <h1>Loading</h1>
          </div>
        }
      </div>
      <Footer />

    </>
  )
}

export default Blogs