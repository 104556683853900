import React from "react";
import style from "./Footer.module.css";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const openExternalLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <div className={style.main}>
        <div className={style.innerDiv}>
          <img
            loading="lazy"
            className={style.baroom}
            src={"/LandingPageImages/Barrom.svg"}
            alt=""
          />
          <div className={style.linkItems}>
            <Link
              to="/about-us"
              className={style.istLink}
              style={{ textDecoration: 'none', color: 'white' }}
            >
              About Us
            </Link>
            <Link
              to="/privacy-policy"
              className={style.istLink}
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-conditions"
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Terms & Conditions
            </Link>
          </div>
          <p>Subscribe To Our Newsletter</p>
          <div className={style.inputArea}>
            <input type="email" placeholder="Enter Your Email Address" />
            <button className={style.inpBtn}>Subscribe</button>
          </div>
          <div className={style.logoPart}>
            <img
              src={"/Footer/facebook.svg"}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                openExternalLink(
                  "https://www.facebook.com/profile.php?id=61559697518079&mibextid=ZbWKwL"
                )
              }
            />
            <img
              src={"/Footer/insta.svg"}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                openExternalLink(
                  "https://www.instagram.com/barooombartenders?igsh=Z2ZlYTE0a3VianI3"
                )
              }
            />
            <img
              src={"/Footer/crossicon.png"}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                openExternalLink("https://www.linkedin.com/company/barooom/")
              }
            />
          </div>
          <div style={{ border: '1px solid #5B5753', width: '30vw' }} />
          <div
            style={{
              textAlign: "center",
              width: "100%",
              color: 'white',
              justifyContent: "center",
            }}>
            <h1 style={{ marginBottom: "10px", fontFamily: 'Raleway', fontSize: '1rem', fontWeight: '400' }} >
              Copyright © 2024 barooom. All Rights Reserved.
            </h1>
            <h1 style={{ fontFamily: 'Raleway', fontSize: '1rem', fontWeight: '400' }}>A Product By The Cottrill Group, Inc.</h1>
          </div>
        </div>
      </div >
    </>
  );
};

export default Footer;
