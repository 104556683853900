import React from "react";
import { useSelector } from "react-redux";
import ContentWrapper from "../component/ContentWrapper/ContentWrapper";
import Footer from "../component/landing/Footer";

export default function AboutUs() {
  
  return (
    <>
      <ContentWrapper
        type={"AboutSection"}
        title={["About", "US"]}
        content={"<h1>Just and HTML</h1>"}
      />

      <Footer />
    </>
  );
}
