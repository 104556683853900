import * as React from "react";
import ContentWrapper from "../component/ContentWrapper/ContentWrapper";
import Footer from "../component/landing/Footer";

function TermCondion() {

  return (
    <>

      <ContentWrapper
        type={"TermsCondition"}
        title={["Terms", "& CONDITIONS"]}
        content={"<h1>Just and HTML</h1>"}
      />

      <Footer />

    </>

  );
}
export default TermCondion;




