import React, { useEffect, useState } from 'react'
import { FetchBlog, FetchBlogsList } from '../axios/axios'
import './Blogs.css'
import Footer from '../component/landing/Footer'
import { useSelector } from 'react-redux'
import style from "../component/ContentWrapper/ContentWrapper.module.css";
import { Link, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Helmet } from 'react-helmet';

const BlogDetails = () => {
    const user = useSelector((state) => state?.user?.user);
    const { bid } = useParams()
    const [data, setdata] = useState({})
    const getBlogs = () => {
        FetchBlog({ bid }).then((result) => {
            setdata(result.data);
        }).catch((err) => {
            console.log(err);

        })
    }

    useEffect(() => {
        getBlogs()
    }, [])

    return (
        <>
            <div
                style={{
                    background: `url(/AboutBarrom/backgroundImg.png)`,
                    backgroundSize: "cover",
                }}>
                <header className={style.header}>
                    <Helmet>
                        <title>{data?.metaTags?.title}</title>
                        <meta name="description" content={data?.metaTags?.description} />
                    </Helmet>
                </header>
                <Link to={"/"}>
                    <img
                        loading="lazy"
                        className={style.barrom}
                        src={"/LandingPageImages/Barrom.svg"}
                        alt=""
                    />
                </Link>
                <div className={style.lowerPart}>
                    <div className={style.backgroundImg}>
                        <h3>
                            Barooom <span>Blogs</span>
                        </h3>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', }}>
                <p onClick={() => window.history.back()} style={{ marginLeft: '20px', alignContent: 'center' }}><ArrowBackIosNewIcon /></p>
                <h1 style={{ textAlign: 'center', marginLeft: '30px' }}>{data.title}</h1>
            </div>
            <div className={style.middlePart}>
                <div
                    className={style.contentSection}
                    dangerouslySetInnerHTML={{ __html: data?.content }}></div>
            </div>
            <Footer />

        </>
    )
}

export default BlogDetails