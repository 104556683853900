import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, updateUser } from "../redux/userSlice";
import { setUnAuthorized } from "../redux/globalSlice";
import { triggerBackgroundAuthentication } from "../axios/axios";
import ModalBox from "../component/modal";
import { ConstructionOutlined } from "@mui/icons-material";

export const globalData = createContext(null);

export const GlobalContext = (props) => {
  const unauthorized = useSelector((state) => state.global.unauthorized);
  const dispatch = useDispatch();
  const [allow, setAllow] = useState(false);

  // auto sets the user if user is not logged out last time
  useEffect(() => {
    const usr =
      localStorage.getItem("bsuser") || sessionStorage.getItem("bsuser");

    if (usr) {
      const usr2 = JSON.parse(usr);

      triggerBackgroundAuthentication(usr2.token)
        .then((result) => {
          const prep = {
            ...result.data.data.user,
            token: result.data.data.token,
          };
          dispatch(updateUser(prep));
          // dispatch( login(usr2) );
          setAllow(true);
        })
        .catch((err) => {
          console.log("error from gcontext", err);
          dispatch(setUnAuthorized(true));
        });
    } else {
      setAllow(true);
    }
  }, []);

  return (
    <globalData.Provider value={null}>
      {allow && <>{props.children}</>}

      <ModalBox
        open={unauthorized}
        onClose={() => {}}
        header="Restricted !"
        message={"Login session expired. Please Login again !"}
        onOk={() => {
          dispatch(logout());
          dispatch(setUnAuthorized(false));
          setAllow(true);
        }}
      />
    </globalData.Provider>
  );
};
